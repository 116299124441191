// This is the template for blog posts (single as per WP convention)
// TODO: Hardcoded content (in dynamic template)

import { graphql } from 'gatsby';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import CTASectionTrial from '../../components/CTASectionTrial/CTASectionTrial';
import DefaultPageHeader from '../../components/DefaultPageHeader/DefaultPageHeader';
import FeatureBlock from '../../components/FeatureBlock/FeatureBlock';
import PageSection from '../../components/PageSection/PageSection';
import PageTemplate from '../../components/PageTemplate/PageTemplate';
import { PageComponent } from '../../types/gatsby.types';
import { ColorPalette } from '../../utils/colors.utils';
import './single-feature.scss';

// Notice filter ne (notequal) in allMdx query, using the id of the current post)

// TODO: Apparently this template {mdx.slug} will create routes for all mdx files
// (so there's a bit of extra routes when checking the 404 pages)

// It seems this can be addressed with createNode so it creates new nodes that can be queried especifically on graphql

export const query = graphql`
query xinsightsFeatureQuery ($id: String) {
  singlePost: mdx(id: {eq: $id}, fileAbsolutePath: {regex: "/features/"}) {
    body
    frontmatter {
      title
      description
      overline
      featured_video {
        publicURL
      }
      featured_image {
        childImageSharp {
          gatsbyImageData
        }
      }
      color
    }
    id
    slug
  }
}
`

const SingleFeature: PageComponent = props => {

  // const featuredImage = getImage(props.data.singlePost.frontmatter.featured_image)

  return <Observer children={() => (
    <PageTemplate 
      {...props} // always include
      className="SingleFeature"
      title={props.data.singlePost.frontmatter.title}
      description={props.data.singlePost.frontmatter.description}
      backgroundColor={ColorPalette.white}
      foregroundColor={ColorPalette.dark}
    >

      <DefaultPageHeader
        id="SingleFeatureHeader"
        className="SingularPageHeader" // Singular is the WP naming for both default pages + posts + custom post types (as a fallback)
        // title={props.data.singlePost.frontmatter.title}
        overline="trackster feature"
        title="Visualise performance with Insights"
        subtitle="Visualise your entire team's performance with just a couple of clicks"
      ></DefaultPageHeader>

      <PageSection id="SingleFeatureArticle SingleBlogArticle">

        {
          props.data.singlePost.frontmatter.featured_video.publicURL ? 
          // We need the wrapping div to be able to style the video appropriately
          <div className="FeatureBlockVideo"> 
            <video autoPlay muted loop 
            // poster={image.publicURL}
            // TODO: Edge cases (no image / no video, etc)
            >
              <source src={props.data.singlePost.frontmatter.featured_video.publicURL} type="video/mp4"/>
            </video>
          </div>
          : null
          }

        {/* <GatsbyImageAdaptor image={featuredImage} /> */}

        <article className="SingleBlogArticleContent SingleFeatureArticleContent">
          <p className='SingleFeatureDescription'>{props.data.singlePost.frontmatter.description}</p>

          <FeatureBlock
          id=""
          title="See only the breakdown you need"
          image={props.data.singlePost.frontmatter.featured_image}
          overline="Filter everything"
          >
            <p>Filter by workspace, Project, Task or Users (combined or not) to dynamically see all the data that is important to you </p>
          </FeatureBlock>

          {/* <MDXRenderer>
            {props.data.singlePost.body}
          </MDXRenderer> */}

        </article>


      </PageSection>

      {/* <PageSection id="BlogEntryListSection">
        <h2 className="BlogEntryListSectionHeading">Latest articles</h2>
        <BlogEntryList
        id=""
        allMdx={props.data.allPosts}
        ></BlogEntryList>s
      </PageSection> */}

      <CTASectionTrial
      title="Want to test this and more features live?"
      />

    </PageTemplate>
  )} />
}

export default SingleFeature;